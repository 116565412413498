*{
padding:0px;
margin:0px;
box-sizing: border-box;
}
body{
  /* background-color: grey; */
  background-image: url("/12.jpg");
  background-size: cover;
}
a{
  color: white;
}
/* .input-amount{
  border: 1px solid red;
  color: red;
} */
/* input[type=number] {
    padding:5px;
    border:2px solid #ccc;
    -webkit-border-radius: 5px;
    border-radius: 5px;
} */

.logo{
  height:140px;
  top:0%;
  left: 0%;
}

.position{
  margin-top: 300px;
}
.logo-Mobile{
  height: 140px;
  margin: 0 auto;
  display: block;
}
#menu-icon{
  position: fixed;
  padding: 0px 50px 50px 0px;


}
.btns-top{
  position: inherit;
  margin-left: 750px;
}
.btn-login{
  border: 2px solid white;
  border-radius: 9px;
  background-color: #1c242c;
  padding: 5px;
  color: white;
  margin: 0px 0px 0px 10px;

}
.btn-feedback{
  border: 2px solid white;
  border-radius: 9px;
  background-color: #1c242c;
  padding: 5px;
  color: white;
  margin: 0px 10px 0px 10px;
}
.btn-feedback:hover {
  box-shadow: 0px 0px 5px 0.5px white;
  color:white;
}
.btn-feedback:active {
  box-shadow: 0px 0px 2px 0.2px white;
  color:white;
}

#cc-list{
 padding: 20px 20px;
 text-align: center;
 margin: 0 auto;
 min-height: 430px;

}
#cc-list-Mobile{
  text-align: center;
  margin-top:10px;


}

main{
  min-height: calc(100vh - 650px);
}
#navbar{
  max-width: 495px;
  left:15%;

}
 #navbar-mobile{
  position: fixed;
  background-color: white;
  margin:0px;
  padding:20px 0px 0px 0px;
  left:0%;
  top:7%;
  height: 100%;
  width: 100%;
}
#mobile-nav-content{
  padding:40px;
  margin:0px;
  font-size: 30px;
  background-color: #1c242c;
  color: white;
  font-weight: 900;
  text-align: center;
  border: 1px solid white;


}
#mobile-nav-position{
  color: white;
  position: fixed;
  top:0%;
  left:0%;
  z-index: 1000;

}
#commercials{
  min-height: calc(100vh - 612px);
}
#cc-term{
  color: red;
  font-size: 20px;
}
#loan-term{
  font-size: 20px;


}


#mobile-header{
  text-align: center;
}



#cc-term-sav{
  color:green;
  font-size: 20px;
}


#outcomeLabel{
  font-size: 13px;
  font-weight: bold;
}



#budgettool{
  padding: 0px 50px;
}

#btool-button{
  float:right;
  background-color: inherit;
  border:none;
}

#footer{
background-color: #1c242c;
color: white;
width: 100%;
padding: 0px 20px 20px 0px;
position: absolute;

}
#sitemap-header{
  margin-left: 15px;
}
#sitemap{
  margin: 30px 0px 0px 50px;
  display: inline-block;
  display:inline-block;
  width: 300px;
}
#sitemap-mobile{

  /* width: 200px; */
}
.sitemap-lists{
    display: inline-grid;
    margin:0px 0px 0px 20px;
}
.link-sitemap{
  padding: 10px;
}

#copyrights{
  max-width: 500px;
  float: right;
  margin-top: 120px;
  padding: 20px 0px 0px 0px ;
  display: inline-block;
  text-transform: uppercase;
  text-align: right;
}




#income{
  background-color: lightgreen;
  font-weight: 900;
  font-family: sans-serif;
  margin: 4px 0px 0px 0px;
}

#outgoing{
  background-color: #ffb84d;
  font-weight: 900;
  font-family: sans-serif;
  margin: 4px 0px 0px 0px;
}
#difference{
  font-size: 20px;
}


#max-width{
  text-align: center;
  max-width: 900px;
  display:  inline-table;
  color: black;
}
#max-width-Mobile{
  max-width: 800px;
}
#max-width-getstarted{
  margin: 20px 0px;
  display: inline-flex;
}
#max-width-getstarted-mobile{
  margin: 20px 0px;
  height: 120%;
}

#getstarted-size-Mobile{
/* display: block; */
margin-bottom: 50px;
}
#getstarted-img-cc-mobile{
  max-width:100%;
}
#max-width-home-pc{
  margin: auto;
  padding:50px 0px;
  max-width: 800px;
  display: flex;
  /* float: left; */
}
#max-width-home-Mobile{
  /* max-width: 900; */
  display: inline-block;
  padding:10px 0px;

  /* float: left; */
}
#home-size-PC{
  color: #1c242c;
  max-width:300px;
  text-align: center;
  justify-content: center;
  margin: auto;
  font-size: 20px;

}

#home-tip{
  width: 370px;
  padding:30px;
  text-align: center;
  color: white;
  height: 300px;
  background-color: #1c242c;
  text-align: center;
  justify-content: center;
}
#home-tip-Mobile{
  padding:30px;
  text-align: center;
  color: white;
  width:220px;
  height: 300px;
  margin: auto;
  background-color: #1c242c;
}


#home-break-div{
  background-color: #1c242c;
  height: 100px;
  margin: 0 auto;
}
#home-size-Mobile{
  margin-top: 100px;
  mrgin: 0 auto;

  display:inline-block;
}
#form-position{
  display: inline-block;
  text-align: center;
  padding: 10px;
  border-radius: 4px;
  background-color: #f6f7f8;
  border: 1px solid lightgrey;


}
#form-position-big{
  background-color: #f6f7f8;
  box-shadow: 0px 0px 5px 2px lightgrey;
  padding: 10px;
  border-radius: 4px;
  display: inline-block;
}
#form-align{
  display: inline-block;
  text-align: center;
}
#addsPC{
  position: fixed;
  opacity: 0.5;
  min-width: 200px;
  max-width: 300px;
  min-height: 300px;
  max-height: 500px;
  background-image: url('image1.jpg');
  /* background-size: cover; */
  top:20%;
  left: 80%;
}

#addsMobile{
  opacity: 0.5;
  text-align: center;
  min-width: 100%;
  max-width: 300px;
  min-height: 200px;
  max-height: 500px;
  background-image: url('image1.jpg');
  /* background-size: cover; */

}
#navbar-PC{
  position: absolute;
  display:  inline-table;
  top: 220px;
}
#topcolor{
  background-color: #1c242c;
  height: 120px;
  width: 100%;
  color: white;
  padding: 10px;
}

#adds:hover{
  box-shadow: 0px 0px 30px 2px lightgrey;
}


#headercc{
  text-align: center;
}
#headercc-popup{
  text-align: center;
  box-shadow: 0px 5px 10px 1px lightgrey;
}
#bt-outcome{
  text-align: center;
  font-size: 16px;
  font-weight: bold;
}
#savings-banner{
  color: green;
  font-size: 20px;

}

#hide{
  color: white;
}

#getstarted-size-PC{
  text-align: left;
  max-width: 300px;
  margin:50px 0px 50px 150px;

}
#getstarted-img-cc{
  max-width: 80%;
}
#getstarted-img-loan{
  width:600px;
  position:absolute;
  top:710px;
  margin:0px 0px 0px 500px;
}
#getstarted-img-budgettool{
  width:600px;
  position:absolute;
  top:1105px;
  margin:0px 0px 0px 500px;
}

#navbar-PC-new{
  position: absolute;
  display: inline-flex;
  margin:0 auto;
  float: left;
  top:108px;
  width: 1000px;
  text-align: center;
  margin: -100px 0px 0px -10px;
}
#navbar-PC-Mobile{
  color: white;
}
#navbar-new-li{
  margin:0px 0px 0px 10px;
  display: inline-block;
  color: red;
  text-decoration: none;
  font-size: 35px;
  font-weight: 300;
  max-height: 150px;
  opacity: 0.5;

}
#navbar-new-li-mobile{
  opacity: 1;
  font-size: 30px;
}
#navbar-new-li:hover{
  opacity: 1;
  color: white;
}
a:hover{
  color: white;

}
#navbar-new-li:hover .overlay{
  opacity: 1;
}
#navbar-new-li-active {
  margin:0px 0px 0px 10px;
  display: inline-block;
  color: white;
  text-decoration: none;
  font-size: 35px;
  /* font-weight: 500; */
  max-height: 150px;
  opacity: 1;
}
#navbar-new-ul{
  margin: -74px 0px 0px 220px;
  list-style-type: none;
  padding: 0;
  width: 1200px;
  position: absolute;
}
#navbar-new-ul-mobile{
  top: 40px;
  position: fixed;
  list-style-type: none;
  background-color: white;
  padding:10px 10px 10px 0px;
  box-shadow: 0px 0px 5px 0.5px white;

}

.overlay{
  opacity:0;
}

.overlay-active{
  display: flex;
  justify-content: center;
  border-radius: 25px;
  background-color: white;
  margin: 11px 0px 0px 0px;
  margin-right: auto;
  margin-left: auto;
  height:1.5px;
  width:90%;
  opacity: 1;
}
#feedback-form-pc{
  display: inline-grid;
  background-color: #1c242c;
  padding: 30px;
  color: white;
}
#feedback-form-Mobile{
  display: inline-grid;
  background-color: #1c242c;
  padding: 30px;
  color: white
}
#feedback-name{
  margin: auto;
  border-radius: 4px;
  height: 30px;
  max-width: 300px;
  background-color: #F5F5F5;
}

.btn-feedback-submit{

  margin: 0 auto;
  border: 2px solid white;
  border-radius: 9px;
  background-color: #1c242c;
  padding: 5px;
  color: white;
  margin: 0px 10px 0px 10px;
  max-width: 200px;
}
.btn-feedback-submit:hover{
  box-shadow: 0px 0px 5px 0.5px white;
  color:white;
}
#textarea{
  background-color: #F5F5F5;
}
#hat-span{
  font-size: 25px;
}
#hat-ul{
  background-color: lightgrey;
  max-width: 80%;
  text-align: center;
  margin-left: 30px;
}
#hat-li{
  text-align: left;
  padding:3px 5px;
}

#hat-container{
  text-align: left;
  margin: auto;
  max-width: 700px;
  font-size: 20px;
  padding: 20px 0px;
}
#hat-icon{

}

#socialmedia{
  max-width: 200px;
  padding: 10px;

  float: right;
}
#socialmedia-Mobile{
  max-width: 100px;
  padding: 10px;

  float: right;
}

#overall-input-higherpayment{
  width: 50px;
}
#btn-delete-higherPayment{
    display: inline-flex;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
#chart{
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: #E4E4E4; */
  max-width: 100%;
}
.chart-cc{
  display: inline-flex;
}
#chart-mobile{
  max-width: 600px;
  margin-left: -100px;

}
#chart-top{
  display: inline-flex;
}

.examples{
  color: grey;
  font-size: 13px;

}
